import React from "react";
import './styles.scss';
import axios from "axios";
import axiosRetry from "axios-retry";
import {
  Alert,
  Button,
  Container,
  DatePicker,
  ExpandableSection,
  Form,
  FormField,
  Header,
  Input,
  SpaceBetween,
  Spinner,
  StatusIndicator,
  Table
} from '@amzn/awsui-components-react/polaris';
import { getAppSetting } from "../../config/AppSettings";

const statusMap = {
  "FAILED": 'error',
  "INITIALIZED": 'pending',
  "PENDING": 'loading',
  "RUNNING": 'loading',
  "COMPLETED": 'success'
};

class WorkflowView extends React.Component<any, any> {
  // Constructor
  constructor(props) {
    super(props);
    this.state = {
      items: {},
      isLoaded: false,
      loading: false,
      errors: '',
      hasErrors: false,
      buid: '',
      workflowName: '',
      effectiveDate: '',
      namespace: '',
      version: ''
    };

    const params = this.props.match.params
    if (Object.keys(params).length != 0) {
      this.state = {
        buid: params.buid,
        workflowName: params.workflowName,
        effectiveDate: params.effectiveDate,
        namespace: params.namespace,
        version: params.version,
        loading: true
      }
      this.getWorkflow()
    }
  }

  setBuid(event) {
    this.setState({buid: event})
  }

  setWorkflowName(event) {
    this.setState({ workflowName: event })
  }

  setEffectiveDate(event) {
    this.setState({ effectiveDate: event })
  }

  setNamespace(event) {
    this.setState({ namespace: event })
  }

  setVersion(event) {
    this.setState({ version: event })
  }

  handleSubmit(e) {
    this.getWorkflow()
    e.preventDefault()
  }

  buildUrl() {
    let url = "/" + this.state.buid + "/" + this.state.workflowName + "/" + this.state.effectiveDate + "/"
    if(this.state.namespace != '' && this.state.namespace != undefined) {
      url += this.state.namespace
    }
    this.props.history.push({
      pathname: url
    })
  }

  getWorkflow() {
    this.setState({
      items: {},
      loading: true,
      isLoaded: false,
      hasErrors: false,
      errors: ''
    })
    axiosRetry(axios, {
      retries: 3, // number of retries
      retryDelay: (retryCount) => {
        return 5000; // time interval between retries
      },
      retryCondition: (error) => {
        return error.response?.status === 500; // Lambda coldstart sometimes returns 500
      },
    });
    axios({
      url: 'https://' + getAppSetting('apiUrl') + `/v1/sso/query`,
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      data: this.state
    })
      .then((res) =>
      {
        console.log(res)
        this.setState({
          items: res.data as WorkflowResponse,
          isLoaded: true,
          loading: false
        });
      })
      .catch(error => {
        if(error.response) {
          let res = error.response.data
          console.log(res)
          console.log(error)
          this.setState({
            loading: false,
            hasErrors: true,
            errors: res
          });
        }
      })
    this.buildUrl()
  }

  render() {
    return (
      <form onSubmit={e => this.handleSubmit(e)}>
        <Container
          header={
            <Header variant="h2">
              Workflow Execution Viewer
            </Header>
          }
        >
          <Form
            errorText={this.state.errors}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="primary">Submit</Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween direction="vertical" size="l">
              <FormField label="Buid">
                <Input
                  onChange={({ detail }) => this.setBuid(detail.value)}
                  value={this.state.buid}
                  placeholder="Enter your workflow's buid"
                />
              </FormField>
              <FormField label="Workflow">
                <Input
                  onChange={({ detail }) => this.setWorkflowName(detail.value)}
                  value={this.state.workflowName}
                  placeholder="Enter your workflow name"
                />
              </FormField>
              <FormField label="Effective Date">
                <DatePicker
                  onChange={({ detail }) => this.setEffectiveDate(detail.value)}
                  value={this.state.effectiveDate}
                  openCalendarAriaLabel={selectedDate =>
                    "Choose Date" +
                    (selectedDate
                      ? `, selected date is ${selectedDate}`
                      : "")
                  }
                  nextMonthAriaLabel="Next month"
                  placeholder="YYYY/MM/DD"
                  previousMonthAriaLabel="Previous month"
                  todayAriaLabel="Today"
                />
              </FormField>
              <FormField label="Namespace">
                <Input
                  onChange={({ detail }) => this.setNamespace(detail.value)}
                  value={this.state.namespace}
                  placeholder="Enter your namespace (optional)"
                />
              </FormField>
              <FormField label="Version">
                <Input
                  onChange={({ detail }) => this.setVersion(detail.value)}
                  value={this.state.version}
                  placeholder="Enter your Version (optional)"
                />
              </FormField>
            </SpaceBetween>
          </Form>
        </Container>

        {this.state.loading && (
          <Container>
            <Spinner />
          </Container>
        )}

        {this.state.isLoaded && (
          <Table
            columnDefinitions={[
              {
                id: "workflowName",
                header: "Workflow name",
                cell: item => item.workflowName || "-",
                sortingField: "workflowName"
              },
              {
                id: "status",
                header: "Status",
                cell: item => <StatusIndicator type={statusMap[item.status]}> {item.status}</StatusIndicator>|| "-",
                sortingField: "status"
              },
              {
                id: "businessUnitId",
                header: "Business Unit ID",
                cell: item => item.businessUnitId || "-",
                sortingField: "businessUnitId"
              },
              {
                id: "effectiveDate",
                header: "Effective Date",
                cell: item => item.effectiveDate || "-",
                sortingField: "effectiveDate"
              },
              {
                id: "version",
                header: "Version",
                cell: item => item.version || "-",
                sortingField: "version"
              }
            ]}
            items={[
              {
                workflowName: this.state.items.workflowIdentifier.liveWorkflowIdentifier.workflowKey.namedWorkflowKey.workflowName,
                status: this.state.items.status,
                businessUnitId: this.state.items.workflowIdentifier.liveWorkflowIdentifier.workflowKey.namedWorkflowKey.businessUnitId,
                effectiveDate: this.state.items.workflowIdentifier.liveWorkflowIdentifier.workflowKey.namedWorkflowKey.effectiveDate,
                version: this.state.items.workflowIdentifier.liveWorkflowIdentifier.version
              }
            ]}
            loadingText="Loading resources"
            sortingDisabled
            header={<Header> Workflow Details </Header>}
          />
        )}

        {this.state.items?.stages?.length > 0 && (
          <Container>
            {
              this.state.items.stages.map((stage: WorkflowStage) => {
                return (
                  <ExpandableSection key={"stage-"+stage.stage} defaultExpanded header={"Stage: " + stage.stage}>
                    <Table
                      columnDefinitions={[
                        {
                          id: "name",
                          header: "Step name",
                          cell: item => (item?.url ? <a href={item.url}>{item.name}</a> : item.name) || "-",
                          sortingField: "workflowName",
                          minWidth: 200
                        },
                        {
                          id: "status",
                          header: "Status",
                          cell: item => <StatusIndicator type={statusMap[item.status]}> {item.status}</StatusIndicator> || "-",
                          sortingField: "status"
                        }
                      ]}
                      items={stage.stepRuns}
                      loadingText="Loading resources"
                      sortingDisabled
                    />
                  </ExpandableSection>
                )
              })
            }
            {this.state.items?.details && (
              <Alert
                dismissAriaLabel="Close alert"
                header="Details"
              >
                {this.state.items.details}
              </Alert>
            )}
          </Container>
        )}
      </form>
    );
  }
}

export default WorkflowView;